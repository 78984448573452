define('sags-front-bo/routes/administrator/external-server-error', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      var endDate = new Date();
      endDate.setHours(0, 0, 0, 0);
      endDate.setDate(endDate.getDate() + 1);

      var startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(endDate.getDate() - 7);

      controller.set('serverSideFilters.startDate.value', startDate);
      controller.set('serverSideFilters.endDate.value', endDate);

      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});