define('sags-front-bo/controllers/controls/nfps', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNT_PER_PAGE = 10;
  var DEFAULT_DEBOUNCE_TIME = 500; // ms
  var FIRST_PAGE = 0;
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    tableColumns: [{
      'key': 'identification',
      'displayName': 'Identification',
      'customComponent': 'link-to-nfps',
      'sortable': false
    }, {
      'key': 'vehiclePlate',
      'displayName': 'Immatriculation',
      'sortable': false
    }, {
      'key': 'status',
      'displayName': 'Statut',
      'sortable': false,
      transform: function transform(status) {
        return _dictionary.default.nfpsStatus[status.toUpperCase()];
      }
    }, {
      'key': 'reason',
      'displayName': 'Raison',
      'sortable': false,
      transform: function transform(reason) {
        return _dictionary.default.nfpsReason[reason];
      }
    }],

    date: new Date(),

    needResetChild: false,

    linkComponentConfig: {
      targetField: 'idNfps'
    },

    nfpsFocus: null,
    pictureList: null,

    initRequest: function initRequest(controller) {
      var bodyRequest = {
        datetime: controller.get('date').toISOString(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE
      };
      controller.getNfps(bodyRequest);
    },


    pagesToDisplay: Ember.observer('linkComponentConfig', function () {
      console.log(this.get('linkComponentConfig'));
    }),

    getNfps: function getNfps(request) {
      var _this = this;

      this.get('ajax').request(_environment.default.APP.host + '/control/api/nfps', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: this.get('currentUser.city')
        },
        contentType: 'application/json',
        data: request
      }).then(function (data) {
        _this.set('model', data);
      });
    },
    getNfpsById: function getNfpsById(id) {
      var _this2 = this;

      return this.get('ajax').request(_environment.default.APP.host + '/control/api/get-nfps-by-id?id=' + id, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this2.set('nfpsFocus', data);
        _this2.setPicture();
      });
    },
    setPicture: function setPicture() {
      var controlPicture = this.get('nfpsFocus.controlPictureDTOList');
      var precontrolPicture = this.get('nfpsFocus.precontrolPictureDTOList');
      if (controlPicture !== null && controlPicture.length > 0) {
        this.set('pictureList', controlPicture);
      } else if (precontrolPicture !== null && precontrolPicture.length > 0) {
        this.set('pictureList', precontrolPicture);
      } else {
        this.set('pictureList', null);
      }
    },
    getVehiclePlate: function getVehiclePlate() {
      var vehiclePlateSearch = document.getElementById('nfps-vehicle-plate-search');
      return vehiclePlateSearch.value.trim() === "" ? null : '%' + vehiclePlateSearch.value.toUpperCase() + '%';
    },
    refreshNfps: function refreshNfps() {
      var bodyRequest = {
        datetime: this.get('date').toISOString(),
        vehiclePlate: this.getVehiclePlate(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE
      };

      this.getNfps(bodyRequest);

      this.set("needResetChild", !this.get("needResetChild"));
    },


    actions: {
      prepareRefresh: function prepareRefresh() {
        Ember.run.debounce(this, this.refreshNfps, DEFAULT_DEBOUNCE_TIME);
      },
      openModal: function openModal(id) {
        this.getNfpsById(id);
      },
      closeModal: function closeModal() {
        this.set('nfpsFocus', null);
      },
      sortNFps: function sortNFps(params) {
        var pageNumberToSend = params.pageNumber - 1; // page 1 -> page[0]

        var bodyRequest = {
          datetime: this.get('date').toISOString(),
          vehiclePlate: this.getVehiclePlate(),
          pageNumber: pageNumberToSend,
          countPerPage: COUNT_PER_PAGE
        };

        this.getNfps(bodyRequest);
      },
      updateSharedVariable: function updateSharedVariable(id) {
        this.getNfpsById(id);
      }
    }
  });
});