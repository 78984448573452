define('sags-front-bo/components/blurable-image', ['exports', 'sags-front-bo/utils/converter'], function (exports, _converter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _zoom = 1;

  exports.default = Ember.Component.extend({
    id: null,
    picture: null,
    canvas: null,
    blurredAreas: [],
    selectedArea: null,
    coordinatesForYumain: {},
    scale: 1,
    datas: null,

    init: function init() {
      this._super.apply(this, arguments);
      // remet à 0 les zones précedemment floutées
      this.blurredAreas = [];
    },


    pdfData: Ember.computed('attachmentBase64Vao', function () {
      return (0, _converter.base64toObjectUrl)(this.get('attachmentBase64Vao'), 'application/pdf');
    }),

    showAttachment: function showAttachment(isImage, img, attachment, that) {
      that.set('attachmentShown', attachment);
      that.set('attachmentBase64Vao', img);
      that.set('isAttachmentModalVaoVisible', true);
      that.set('isAttachmentImage', isImage);
    },

    closeAttachmentModalVao: function closeAttachmentModalVao(that) {
      that.set('attachmentShown', null);
      that.set('attachmentBase64Vao', null);
      that.set('isAttachmentModalVaoVisible', false);
    },

    // défini ici pour pouvoir l'appeler via le bouton annuler
    refreshView: function refreshView(blurredAreas, id) {
      var blur = document.getElementById('blur-' + id);
      if (blurredAreas.length > 0) {
        // Generation du clip path
        var clipPath = []; // Liste des rectangles à flouter
        var returnPath = []; // chemin de retour
        // on utilise des proportions : on multiplie par la taille de l'image
        var stringify = function stringify(p) {
          return clipPath.push(p.x * blur.width + 'px ' + p.y * blur.height + 'px,');
        };

        clipPath.push('polygon(');
        blurredAreas.forEach(function (rectangle) {
          // ordre important : aller de point adjacent en point adjacent
          // créer une fonction si une géométrie plus complexe est demandée
          stringify(rectangle.topLeft);
          stringify(rectangle.topRight);
          stringify(rectangle.bottomRight);
          stringify(rectangle.bottomLeft);
          // ajouter le premier point évite que les chevauchements
          stringify(rectangle.topLeft);

          returnPath.push(rectangle.topLeft);
        });
        // le premier et dernier élément de returnPath sont inutiles
        // le retour se fait du dernier point au premier, il faut reverse
        returnPath.pop();
        returnPath.reverse();
        returnPath.pop();
        returnPath.forEach(function (point) {
          return stringify(point);
        });

        // on supprime la dernière virgule
        var str = clipPath[clipPath.length - 1];
        clipPath[clipPath.length - 1] = str.substring(0, str.length - 1);

        // on termine la string et on la génère
        clipPath.push(')');
        var pathString = clipPath.join('');

        // on ajoute nos zones floutés et on rend le masque visible
        blur.style.clipPath = pathString;
        blur.style.visibility = 'visible';
      } else {
        // pas de zone floutée : on cache le masque
        blur.style.clipPath = '';
        blur.style.visibility = 'hidden';
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var that = this;
      var id = this.id;
      var visible = document.getElementById('visible-' + id);
      this.set("blur", document.getElementById('blur-' + id));
      this.canvas = document.getElementById('canvas-' + id);
      var img = document.getElementById('blur-' + id);

      var messageById = function messageById(id) {
        var message = 'Erreur';
        switch (id) {
          case 'picture-overview':
            message = 'Photo de contexte 1';
            break;
          case 'picture-overview1':
            message = 'Photo de contexte 2';
            break;
          case 'picture-overview2':
            message = 'Photo de contexte 3';
            break;
          case 'picture-overview3':
            message = 'Photo de contexte 4';
            break;
          case 'picture-overview4':
            message = 'Photo de contexte 5';
            break;
          case 'picture-overview5':
            message = 'Photo de contexte 6';
            break;
          case 'picture-overview6':
            message = 'Photo de contexte 7';
            break;
          case 'picture-plate':
            message = 'Plaque d\'immatriculation';
            break;
        }
        return message;
      };
      var message = messageById(id);

      // on charge tout une fois l'image chargée, de sorte à éviter d'avoir
      // une taille à 0
      img.addEventListener("load", function () {
        var c = _this.canvas;
        // on resize le canvas à la taille apparente des images
        c.width = visible.width;
        c.height = visible.height;

        var isLeftDown = false;
        var x1 = 0;
        var x2 = 0;
        var y1 = 0;
        var y2 = 0;
        var blurredAreas = _this.blurredAreas;

        // objet permettant de discriminer chaque instance de ce composant
        var currentTable = {
          id: id, areas: [],
          yumain: {
            send: false,
            x: null,
            y: null
          }
        };

        blurredAreas.push(currentTable);
        // pour pouvoir extraire les données de floutage
        _this.get('datas').push(currentTable);
        var refresh = _this.refreshView;
        var show = _this.showAttachment;

        c.addEventListener("mousedown", function (e) {
          if (e.button === 0) {
            // début de selection : on init les coordonnées
            // il faut se baser sur les proportions de l'image
            // l'affichage peut par exemple etre redimensionné
            var coord = leftDown(e);
            x1 = coord.x;
            y1 = coord.y;
          }
        });

        c.addEventListener("mousemove", function (e) {
          if (e.button === 0) {
            if (isLeftDown) {
              var coord = position(e);
              var ctx = c.getContext('2d');
              // on rafraichi le canevas
              clearCanvas(ctx);
              // dessiner le rectangle de selection
              ctx.beginPath();
              ctx.fillStyle = "rgba(0, 0, 255, 0.3)";
              ctx.rect(x1, y1, coord.x - x1, coord.y - y1);
              ctx.stroke();
              ctx.fill();
            }
          }
        });

        c.addEventListener("mouseup", function (e) {
          if (e.button === 0) {
            var ctx = c.getContext('2d');
            clearCanvas(ctx);

            var coord = leftUp(e);

            x2 = coord.x;
            y2 = coord.y;

            // la souris a bougé : on floute
            if (x1 !== x2 && y1 !== y2) {
              var topLeft = void 0,
                  topRight = void 0,
                  bottomRight = void 0,
                  bottomLeft = void 0;
              var minX = void 0,
                  minY = void 0,
                  maxX = void 0,
                  maxY = void 0;
              minX = 0;
              minY = 0;
              maxX = c.width;
              maxY = c.height;

              // contraint les points à être dans l'image
              var clamp = function clamp(value, min, max) {
                return Math.min(Math.max(value, min), max);
              };
              // ordonne les points d'un axe
              var axis = function axis(p1, p2, min, max) {
                return [p1, p2].map(function (p) {
                  return clamp(p, min, max);
                }).sort(function (a, b) {
                  return a - b;
                });
              };
              // met les coordonnées dans les bons points
              var xPoints = axis(x1, x2, minX, maxX);
              var yPoints = axis(y1, y2, minY, maxY);

              // on arrange les coordonnées et on prend leur proportions
              topLeft = { x: scaleX(xPoints[0]), y: scaleY(yPoints[0]) };
              topRight = { x: scaleX(xPoints[1]), y: scaleY(yPoints[0]) };
              bottomRight = { x: scaleX(xPoints[1]), y: scaleY(yPoints[1]) };
              bottomLeft = { x: scaleX(xPoints[0]), y: scaleY(yPoints[1]) };

              // ajout du rectangle dans blurredAreas
              var b = currentTable.areas;
              b.push({ topLeft: topLeft, topRight: topRight, bottomRight: bottomRight, bottomLeft: bottomLeft });
              refresh(b, id);
            } else {
              // simple clic : on affiche l'image en grand
              show(true, visible.src, message, that);
            }
          }
        });

        // clic droit
        c.addEventListener("contextmenu", function (e) {

          isLeftDown = false;
          e.preventDefault();

          var coordinate = position(e);
          currentTable.yumain.send = true;
          currentTable.yumain.x = scaleX(coordinate.x);
          currentTable.yumain.y = scaleY(coordinate.y);

          drawPointYumain(currentTable);
        });

        function clearCanvas(ctx) {
          ctx.clearRect(0, 0, c.width, c.height);
        }

        function drawPointYumain(currentTable) {
          var c = document.getElementById('canvas-' + currentTable.id);
          var ctx = c.getContext('2d');
          var coord = {
            x: currentTable.yumain.x * img.width,
            y: currentTable.yumain.y * img.height

            // repère visuel pour l'agent : point vert
          };ctx.clearRect(0, 0, c.width, c.height);
          ctx.beginPath();
          ctx.fillStyle = "rgba(0, 255, 0, 0.6)";
          ctx.arc(coord.x, coord.y, 10, 0, Math.PI * 2, true);
          ctx.stroke();
          ctx.fill();
        }

        function leftDown(evt) {
          isLeftDown = true;
          return position(evt);
        }

        function leftUp(evt) {
          isLeftDown = false;
          return position(evt);
        }

        function position(e) {
          var rect = c.getBoundingClientRect();
          return {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
          };
        }

        function scaleY(pos) {
          return positionScale(pos, img.height);
        }

        function scaleX(pos) {
          return positionScale(pos, img.width);
        }

        function positionScale(pos, size) {
          return pos / size;
        }

        // resize event :
        // canvas doit match la taille des images
        // blur doit refreshview pour afficher les zones déjà floutées correctement
        window.onresize = resize;
        function resize() {
          // refresh toutes les images
          blurredAreas.forEach(function (area) {
            // scale les zones déjà floutées
            refresh(area.areas, area.id);
            // scale la taille du canevas
            var currentCanvas = document.getElementById('canvas-' + area.id);
            currentCanvas.height = img.height;
            currentCanvas.width = img.width;
            // repositionne le point yumain si il est affiché
            if (area.yumain.send) {
              drawPointYumain(area);
            }
          });
        }
      });
    },


    actions: {
      cancel: function cancel(id) {
        // trouver l'objet qui correspond à l'id
        var o = this.blurredAreas.find(function (img) {
          return img.id === id;
        });
        // on supprime la derniere zone ajoutée, puis on actualise la vue
        o.areas.pop();
        this.refreshView(o.areas, id);
      },
      debug: function debug() {
        console.log(this.blurredAreas);
      },
      closeAttachmentModalVao: function closeAttachmentModalVao() {
        _zoom = 1;
        this.set('attachmentShown', null);
        this.set('attachmentBase64Vao', null);
        this.set('isAttachmentModalVaoVisible', false);
      },
      zoom: function zoom(event) {
        var image = event.currentTarget;

        if (event.deltaY < 0) {
          _zoom += 0.1;
          image.style.transform = 'scale(' + _zoom + ') ';
        } else if (event.deltaY > 0 && _zoom >= 0.75) {
          _zoom -= 0.1;
          image.style.transform = 'scale(' + _zoom + ')';
        }
      }
    }
  });
});