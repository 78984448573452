define('sags-front-bo/controllers/administrator/external-server-error', ['exports', 'sags-front-bo/config/environment', 'lodash'], function (exports, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNT_PER_PAGE = 20;
  var DEFAULT_DEBOUNCE_TIME = 500; // ms
  var FIRST_PAGE = 0;
  var DEFAULT_ORDER = "DESC";
  var DEFAULT_SORTED_FIELD = "errorDate";
  var WEEK_NB_DAYS = 7;
  var DAY_TO_MS = 1000 * 60 * 60 * 24;
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    tableColumns: [{
      'key': 'partnerName',
      'displayName': 'Nom du prestataire'
    }, {
      'key': 'errorMessage',
      'displayName': 'Message d\'erreur'
    }, {
      'key': 'errorDate',
      'displayName': 'Date de l\'erreur'
    }, {
      'key': 'countError',
      'displayName': 'Nombre d\'erreurs'
    }],
    filters: {
      ErrorDate: {
        comparator: 'contains',
        value: ''
      }
    },
    serverSideFilters: {
      startDate: {
        comparator: 'ErrorDate',
        value: new Date()
      },
      endDate: {
        comparator: 'ErrorDate',
        value: new Date()
      }
    },

    possiblePartnerName: [{ key: '*', value: 'Tous' }, { key: 'polyconseil', value: 'Polyconseil' }, { key: 'parknow', value: 'Parknow' }],

    needResetChild: false,

    showAll: false,

    getErrors: function getErrors(request, showAll) {
      var _this = this;

      var url = _environment.default.APP.host + '/control/api/administrator/external-server-errors';

      this.get('ajax').request(url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        data: request,
        contentType: 'application/json'
      }).then(function (errorsPage) {
        showAll ? _this.set('model.errors', _this.get('model.errors').concat(errorsPage.errors)) : _this.set('model', errorsPage);
      });
    },
    getErrorMessage: function getErrorMessage() {
      var errorMessage = document.getElementById('error-message-search');
      return !errorMessage || errorMessage.value.trim() === "" ? null : '%' + errorMessage.value.toUpperCase() + '%';
    },
    getPartnerName: function getPartnerName() {
      var partnerName = document.getElementById("partner-name-search");
      return !partnerName || partnerName.value === '*' ? null : partnerName.value;
    },
    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller) {
      var startDate = controller.get('serverSideFilters.startDate.value');
      var endDate = controller.get('serverSideFilters.endDate.value');
      if (_lodash.default.isNil(startDate) || _lodash.default.isNil(endDate)) {
        var _endDate = new Date(new Date().getTime() + DAY_TO_MS);
        _endDate.setHours(0, 0, 0, 0);
        controller.set('serverSideFilters.endDate.value', _endDate);

        var _startDate = new Date(_endDate.getTime() - WEEK_NB_DAYS * 2 * DAY_TO_MS);
        controller.set('serverSideFilters.startDate.value', _startDate);
      } else {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        var bodyRequest = {
          // Date UTC, convertie dans le back pour la zone locale.
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          errorMessage: controller.getErrorMessage(),
          partnerName: controller.getPartnerName(),
          pageNumber: FIRST_PAGE,
          countPerPage: COUNT_PER_PAGE,
          order: DEFAULT_ORDER,
          sortedField: DEFAULT_SORTED_FIELD
        };
        controller.getErrors(bodyRequest);
      }
    },
    refreshErrors: function refreshErrors() {
      var bodyRequest = {
        startDate: this.get('serverSideFilters.startDate.value').toISOString(),
        endDate: this.get('serverSideFilters.endDate.value').toISOString(),
        errorMessage: this.getErrorMessage(),
        partnerName: this.getPartnerName(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE,
        order: DEFAULT_ORDER,
        sortedField: DEFAULT_SORTED_FIELD
      };

      this.getErrors(bodyRequest);

      this.set("needResetChild", !this.get("needResetChild"));
    },
    calculateEndDate: function calculateEndDate(startDate, endDate) {
      endDate = new Date(startDate.getTime() + WEEK_NB_DAYS * 2 * DAY_TO_MS);
      $("#end-date-search").datetimepicker("date", endDate);
      this.set('serverSideFilters.endDate.value', endDate);
    },
    calculateStartDate: function calculateStartDate(startDate, endDate) {
      startDate = new Date(endDate.getTime() - WEEK_NB_DAYS * 2 * DAY_TO_MS);
      $("#date-issue-search").datetimepicker("date", startDate);
      this.set('serverSideFilters.startDate.value', startDate);
    },


    actions: {
      updateDate: function updateDate(isStartDate) {
        var startDate = new Date(this.get('serverSideFilters.startDate.value'));
        var endDate = new Date(this.get('serverSideFilters.endDate.value'));

        var diffTime = Math.abs(endDate - startDate);
        var diffDays = Math.floor(diffTime / DAY_TO_MS);

        // Différence de 2 semaines maximum entre les deux dates
        if (startDate > endDate || diffDays > WEEK_NB_DAYS * 2) isStartDate ? this.calculateEndDate(startDate, endDate) : this.calculateStartDate(startDate, endDate);

        this.refreshErrors();
      },
      prepareRefresh: function prepareRefresh() {
        Ember.run.debounce(this, this.refreshErrors, DEFAULT_DEBOUNCE_TIME);
      },
      reload: function reload() {
        this.set('model.errors', []);
        Ember.run.debounce(this, this.refreshErrors, DEFAULT_DEBOUNCE_TIME);
      },
      sortErrors: function sortErrors(params, showAll) {
        var pageNumberToSend = params.pageNumber - 1; // page 1 -> page[0]

        var enabledShowAll = this.get('showAll') !== showAll;

        if (enabledShowAll) this.set('showAll', showAll);

        if (showAll && !pageNumberToSend) this.set('model.errors', []);

        var bodyRequest = {
          startDate: this.get('serverSideFilters.startDate.value').toISOString(),
          endDate: this.get('serverSideFilters.endDate.value').toISOString(),
          errorMessage: this.getErrorMessage(),
          partnerName: this.getPartnerName(),
          pageNumber: pageNumberToSend,
          countPerPage: COUNT_PER_PAGE,
          order: params.order,
          sortedField: params.sortedField
        };

        this.getErrors(bodyRequest, showAll);
      }
    }
  });
});