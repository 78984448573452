define('sags-front-bo/components/dynamic-table-controls', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),

    // Contrairement au composant "dynamic-table", celui-ci est destiné à accueillir beaucoup d'éléments, une pagination côté serveur a été faite pour l'optimisation.

    // content = contenu affiché dans la liste (controle,fps,rapo,... selon la page où le composant est appelé)
    displayedContent: Ember.computed('contentAsEmberObject', function () {
      var content = this.get('contentAsEmberObject');
      if (_lodash.default.isArray(content) && !_lodash.default.isEmpty(content) && !_lodash.default.isFunction(content.get(0).get)) {
        // convertir content en tableau d'objets Ember pour éviter des soucis dans le reste de dynamic-table
        return content.map(function (item) {
          return Ember.Object.create(item);
        });
      }
      return content;
    }),
    contentAsEmberObject: Ember.computed('content', 'content.@each', function () {
      var content = this.get('content');
      if (_lodash.default.isArray(content) && !_lodash.default.isEmpty(content) && !_lodash.default.isFunction(content.get(0).get)) {
        // convertir content en tableau d'objets Ember pour éviter des soucis dans le reste de dynamic-table
        return content.map(function (item) {
          return Ember.Object.create(item);
        });
      }
      return content;
    }),
    sortOrderAsc: false, // Le champ datetime est par défaut en ordre décroissant (date d'ajourd'hui > date plus ancienne)
    sortField: '',
    showAll: false,
    pageNumber: 1,
    // numéros de page à afficher dans le paginator
    pagesToDisplay: Ember.computed('maxPage', 'pageNumber', function () {
      // partir de la page actuelle
      var minDisponible = this.get('pageNumber');
      var maxDisponible = this.get('pageNumber');

      var maxPage = this.get('maxPage');
      // s'étendre des deux côtés tant qu'il y a de la place et qu'on n'a pas atteint 11 pages au total
      while (maxDisponible - minDisponible < 10 && (minDisponible > 1 || maxDisponible < maxPage)) {
        if (maxDisponible < maxPage) maxDisponible++;
        if (maxDisponible - minDisponible < 10 && minDisponible > 1) minDisponible--;
      }

      // créer une liste des pages à afficher (par exemple [3, 4, 5])
      var list = [];
      for (var k = minDisponible; k <= maxDisponible; k++) {
        list.push(k);
      }

      return list;
    }),

    resetList: Ember.observer('needReset', function () {
      this.set('pageNumber', 1);
      this.set('sortOrderAsc', this.get('defaultSortOrderAsc'));
      this.set('sortField', this.get('defaultSortField'));
    }),

    // à l'initialisation et à chaque changement de content, mettre en place à nouveau les transforms
    contentChanged: Ember.observer('contentAsEmberObject', 'content.@each', function () {
      this.get('setupTransformsAndCustomRenderers')(this);
    }),

    // met en place les computed properties permettant le calcul de computedClass et des transforms
    setupTransformsAndCustomRenderers: function setupTransformsAndCustomRenderers(that) {
      var content = that.get('contentAsEmberObject');
      var columns = that.get('columns');

      if (!_lodash.default.isNil(content)) {

        // pour chacune des colonnes qui ont une fonction "transform",
        // on crée une propriété calculée <clé>_transform dans chacun des éléments à afficher
        columns.forEach(function (column) {
          if (_lodash.default.isFunction(column.transform)) {
            var transformId = column.key.replace(/\./g, '_') + '_transform_' + that.get('elementId');
            Ember.set(column, 'transformedKey', transformId);
            content.forEach(function (one) {
              if (_lodash.default.isNil(one.get(transformId))) {
                one.set(transformId, Ember.computed(column.key, function () {
                  return column.transform(one.get(column.key), one);
                }));
              }
            });
          }
        }, that);

        // on appelle setupComputedClassAttribute et setupActionIconVisibleAttribute sur chaque ligne pour mettre en place les propriétés
        if (_lodash.default.isFunction(that.get('setupComputedClassAttribute'))) {
          content.forEach(function (one) {
            if (_lodash.default.isNil(one.get('computedClass_' + that.get('elementId')))) {
              that.get('setupComputedClassAttribute')(one, that.get('elementId'), that.get('currentUser'));
            }
          }, that);
        }
        if (_lodash.default.isFunction(that.get('setupActionIconVisibilityAttribute'))) {
          content.forEach(function (one) {
            if (_lodash.default.isNil(one.get('actionsVisibilityWasSetup_' + that.get('elementId')))) {
              that.get('setupActionIconVisibilityAttribute')(one, that.get('elementId'), that.get('currentUser'));
              one.set('actionsVisibilityWasSetup_' + that.get('elementId'), true);
            }
          }, that);
        }

        if (!_lodash.default.isNil(columns)) {
          columns.forEach(function (column) {
            if (_lodash.default.includes(column.key, '.')) {
              content.forEach(function (one) {
                // on va par exemple créer une propriété calculée 'manager_name' pour référencer 'manager.name'
                Ember.defineProperty(one, column.key.replace(/\./g, '_'), Ember.computed.alias(column.key));
              });
            }
          });
        }
      }
    },


    // méthode du lifecyle de ember appelée après l'init
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('setUp')) {
        // Mise en place des valeurs données en paramètres au composant (voir tout en haut)

        this.set('sortField', this.get('defaultSortField'));
        if (this.get('defaultSortOrderAsc')) {
          this.set('sortOrderAsc', this.get('defaultSortOrderAsc'));
        }
      }

      this.set('setUp', true);
    },


    // méthode du lifecyle de ember appelée après le rendu des éléments dans le DOM
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      addEventListener("scroll", function () {
        if (_this.get('showAll')) {
          _this.send('onScroll');
        }
      });
    },
    handleScrollEnd: function handleScrollEnd() {
      if (this.get('maxPage') > this.get('pageNumber')) {
        this.set('pageNumber', this.get('pageNumber') + 1);
        this.send('goToPage', this.get('pageNumber'));
      }
    },


    actions: {
      showAll: function showAll() {
        var isChecked = document.getElementById('show-all').checked;
        this.set('pageNumber', 1);
        this.set('showAll', isChecked);
        this.send('goToPage', this.get('pageNumber'));
      },
      sort: function sort(key, sortable) {
        if (sortable !== false) {
          var asc = !this.get('sortOrderAsc');
          // lorsque l'on clique sur une nouvelle colonne, le tri est par ordre croissant.
          if (key !== this.get('sortField')) {
            asc = true;
          }

          this.set('sortField', key);
          this.set('sortOrderAsc', asc);

          if (this.get('showAll')) {
            this.set('pageNumber', 1);
          }
        }

        var request = {
          pageNumber: this.get('pageNumber'),
          order: 'ASC',
          sortedField: this.get('sortField')
        };

        if (!this.get('sortOrderAsc')) request.order = 'DESC';

        this.sendAction('sortData', request, this.get('showAll'));
      },
      rowClicked: function rowClicked(item) {
        if (this.get('isClickable')) {
          this.sendAction('rowClicked', item);
        }
      },
      goToPage: function goToPage(page) {
        var request = {
          pageNumber: page,
          order: 'ASC',
          sortedField: this.get('sortField')
        };

        if (!this.get('sortOrderAsc')) request.order = 'DESC';

        this.set('pageNumber', page);
        this.sendAction('sortData', request, this.get('showAll'));
      },
      actionIconClicked: function actionIconClicked(item, index) {
        this.sendAction('actionIcon' + index + 'Clicked', item);
      },
      sendActionThroughDynamicTable: function sendActionThroughDynamicTable() {
        this.sendAction.apply(this, arguments);
      },
      onScroll: function onScroll() {

        // Vérifier si on a atteint la fin de la page
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var documentHeight = document.documentElement.scrollHeight;

        // Si la position de défilement + la hauteur de la fenêtre est égale à la hauteur totale du document
        // -10 pour une petite tolérance (on repère le bas de la page dans une zone de 10px)
        if (scrollPosition + windowHeight >= documentHeight - 10) {

          // Petit debounce pour ne pas catcher l'event plusieurs fois en cas de double scroll
          Ember.run.debounce(this, this.handleScrollEnd, 250);
        }
      },
      updateSharedVariable: function updateSharedVariable(value) {
        this.sendAction('updateSharedVariable', value);
      }
    }
  });
});