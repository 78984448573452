define('sags-front-bo/models/section', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    publicId: _emberData.default.attr('string'),
    groupId: _emberData.default.attr('number'),
    numberOfPlaces: _emberData.default.attr('number'),
    roadType: _emberData.default.attr('string'),
    roadName: _emberData.default.attr('string'),
    district: _emberData.default.attr('number'),
    parity: _emberData.default.attr('string'),
    parkType: _emberData.default.attr('string'),
    placeType: _emberData.default.attr('string'),
    tariffZone: _emberData.default.attr('string'),
    residentialSector: _emberData.default.attr('string'),
    coordinates: _emberData.default.attr(''),
    aggregatedSectionId: _emberData.default.attr('number'),
    roadNumberRange: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    statReg: _emberData.default.attr('string'),
    typeStat: _emberData.default.attr('string')
  });
});