define('sags-front-bo/components/dynamic-table', ['exports', 'lodash', 'moment'], function (exports, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showAll: false,
    currentUser: Ember.inject.service('current-user'),

    // TODO si cette liste est destinée à accueillir plus de 10 000 éléments, prévoir une pagination côté serveur !
    // Le temps de réponse avoisine les 500~700ms avec 10 000 éléments.

    // Pipeline : filtrage => tri => pagination (chaque étape utilise le résultat de la précédente)
    filteredContent: [], // voir méthode didReceiveAttrs()
    sortField: '',
    sortOrderAsc: true,
    // note : le tri par appel explicite à Array.sort() est BEAUCOUP plus efficace que l'utilisation de Ember.computed.sort
    sortedContent: [], // voir méthode didReceiveAttrs()
    paginatedContent: Ember.computed('sortedContent', 'pageNumber', 'countPerPage', 'showAll', function () {
      var firstIndex = (this.get('pageNumber') - 1) * this.get('countPerPage');
      return this.get('showAll') ? this.get('sortedContent') : this.get('sortedContent').slice(firstIndex, firstIndex + this.get('countPerPage'));
    }),

    contentAsEmberObject: Ember.computed('content', 'content.@each', function () {
      var content = this.get('content');
      if (_lodash.default.isArray(content) && !_lodash.default.isEmpty(content) && !_lodash.default.isFunction(content.get(0).get)) {
        // convertir content en tableau d'objets Ember pour éviter des soucis dans le reste de dynamic-table
        return content.map(function (item) {
          return Ember.Object.create(item);
        });
      }
      return content;
    }),

    pageNumber: 1,
    maxPage: Ember.computed('filteredContent', 'countPerPage', 'showAll', function () {
      var filteredContent = this.get('filteredContent');
      // dernière page (nb de pages) = nombre d'éléments / nombre d'éléments par page
      if (!_lodash.default.isNil(filteredContent) && !_lodash.default.isEmpty(filteredContent) && !this.get('showAll')) {
        return Math.ceil(filteredContent.length / this.get('countPerPage'));
      } else if (this.get('showAll')) {
        return 1;
      }

      // cas particuliers : aucun élément ou filteredContent indéfini
      return 1;
    }),

    // numéros de page à afficher dans le paginator
    pagesToDisplay: Ember.computed('maxPage', 'pageNumber', function () {
      // partir de la page actuelle
      var minDisp = this.get('pageNumber');
      var maxDisp = this.get('pageNumber');

      var maxPage = this.get('maxPage');
      // s'étendre des deux côtés tant qu'il y a de la place et qu'on n'a pas atteint 11 pages au total
      while (maxDisp - minDisp < 10 && (minDisp > 1 || maxDisp < maxPage)) {
        if (maxDisp < maxPage) maxDisp++;
        if (maxDisp - minDisp < 10 && minDisp > 1) minDisp--;
      }

      // créer une liste des pages à afficher (par exemple [3, 4, 5])
      var list = [];
      for (var k = minDisp; k <= maxDisp; k++) {
        list.push(k);
      }

      return list;
    }),

    setUp: false,

    // nombre de colonnes réellement affichées dans le tableau (s'il y a des actionIcons, on affiche 1 colonne en plus)
    columnCount: Ember.computed('columns', 'columns.@each', 'actionIcons', function () {
      var columnCount = this.get('columns').length;

      if (!_lodash.default.isNil(this.get('actionIcons'))) {
        return columnCount + 1;
      }

      return columnCount;
    }),

    listSizeChanged: Ember.observer('content.@each', function () {
      // la taille de la liste a changé => retour à la page 1
      this.set('pageNumber', 1);
    }),

    // met en place les computed properties permettant le calcul de computedClass et des transforms
    setupTransformsAndCustomRenderers: function setupTransformsAndCustomRenderers(that) {
      var content = that.get('contentAsEmberObject');
      var filters = that.get('filters');
      var columns = that.get('columns');

      if (!_lodash.default.isNil(content)) {
        that.get('itemsToClean').addObjects(content);

        // pour chacune des colonnes qui ont une fonction "transform",
        // on crée une propriété calculée <clé>_transform dans chacun des éléments à afficher
        columns.forEach(function (column) {
          if (_lodash.default.isFunction(column.transform)) {
            var transformId = column.key.replace(/\./g, '_') + '_transform_' + that.get('elementId');
            Ember.set(column, 'transformedKey', transformId);
            content.forEach(function (one) {
              if (_lodash.default.isNil(one.get(transformId))) {
                one.set(transformId, Ember.computed(column.key, function () {
                  return column.transform(one.get(column.key));
                }));
              }
            });
          }
        }, that);

        // on appelle setupComputedClassAttribute et setupActionIconVisibleAttribute sur chaque ligne pour mettre en place les propriétés
        if (_lodash.default.isFunction(that.get('setupComputedClassAttribute'))) {
          content.forEach(function (one) {
            if (_lodash.default.isNil(one.get('computedClass_' + that.get('elementId')))) {
              that.get('setupComputedClassAttribute')(one, that.get('elementId'), that.get('currentUser'));
            }
          }, that);
        }
        if (_lodash.default.isFunction(that.get('setupActionIconVisibilityAttribute'))) {
          content.forEach(function (one) {
            if (_lodash.default.isNil(one.get('actionsVisibilityWasSetup_' + that.get('elementId')))) {
              that.get('setupActionIconVisibilityAttribute')(one, that.get('elementId'), that.get('currentUser'));
              one.set('actionsVisibilityWasSetup_' + that.get('elementId'), true);
            }
          }, that);
        }

        // on met aussi en place des propriétés calculées pour les sous-attributs
        if (!_lodash.default.isNil(filters)) {
          Object.keys(filters).forEach(function (filter) {
            if (_lodash.default.includes(filter, '_')) {
              content.forEach(function (one) {
                // on va par exemple créer une propriété calculée 'manager_name' pour référencer 'manager.name'
                Ember.defineProperty(one, filter, Ember.computed.alias(filter.replace(/_/g, '.')));
              });
            }
          });
        }
        if (!_lodash.default.isNil(columns)) {
          columns.forEach(function (column) {
            if (_lodash.default.includes(column.key, '.')) {
              content.forEach(function (one) {
                // on va par exemple créer une propriété calculée 'manager_name' pour référencer 'manager.name'
                Ember.defineProperty(one, column.key.replace(/\./g, '_'), Ember.computed.alias(column.key));
              });
            }
          });
        }
      }
    },


    // permet de supprimer les propriétés calculées du modèle lorsque la dynamic-table est détruite
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var itemsToClean = this.get('itemsToClean');

      if (!Ember.testing) {
        // cette méthode est appelée trop tôt en mode test...
        itemsToClean.forEach(function (item) {
          // suppression des <clé>_transform
          _this.get('columns').forEach(function (column) {
            if (_lodash.default.isFunction(column.transform)) {
              item.set(column.transformedKey, undefined);
            }
          }, _this);

          // suppression de computedClass
          if (_lodash.default.isFunction(_this.get('setupComputedClassAttribute'))) {
            item.set('computedClass_' + _this.get('elementId'), undefined);
          }

          // suppression des isIconXVisible
          if (!_lodash.default.isNil(_this.get('actionIcons'))) {
            var iconCount = _this.get('actionIcons').length;
            for (var i = 0; i < iconCount; i++) {
              item.set('isIcon' + i + 'Visible_' + _this.get('elementId'), undefined);
            }
            item.set('actionsVisibilityWasSetup_' + _this.get('elementId'), undefined);
          }
        }, this);

        this.set('itemsToClean', []);
      }
    },


    // à l'initialisation et à chaque changement de content, mettre en place à nouveau les transforms
    contentChanged: Ember.observer('contentAsEmberObject', 'content.@each', function () {
      this.get('setupTransformsAndCustomRenderers')(this);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (!this.get('setUp')) {
        // initialiser la liste des éléments à nettoyer
        this.set('itemsToClean', []);
      }

      this.get('setupTransformsAndCustomRenderers')(this);

      if (!this.get('setUp')) {
        // mettre en place les valeurs par défaut
        this.set('sortField', this.get('defaultSortField'));
        this.set('sortOrderAsc', this.get('defaultSortOrderAsc'));

        // ====== FILTRAGE

        if (_lodash.default.isNil(this.get('filters')) || _lodash.default.isEmpty(this.get('filters'))) {
          // cas spécifique : pas de filtre, donc renvoyer 'contentAsEmberObject'
          Ember.defineProperty(this, 'filteredContent', Ember.computed('contentAsEmberObject.@each', function () {
            // le map transforme l'objet content (objet Ember) en tableau, qui aura les méthodes sort, etc.
            var content = this.get('contentAsEmberObject');
            if (!_lodash.default.isNil(content) && _lodash.default.isFunction(content.map)) {
              return content.map(function (item) {
                return item;
              });
            }
            return content;
          }));
        } else {
          // faire en sorte que le filteredContent se rafraîchisse quand l'un des filtres ou l'une des valeurs filtrables du tableau change
          // (les propriétés à écouter pour la mise à jour sont les champs "value" de chaque filtre,
          //  on ne connaît ces filtres qu'à l'exécution, après réception des attributs.)
          var anyFilteredAttribute = Object.keys(this.get('filters')).join(',');

          Ember.defineProperty(this, 'filteredContent', Ember.computed.filter('contentAsEmberObject', function (content) {
            var result = true;

            // méthode convertissant les valeurs null ou undefined en chaînes vides
            var nullToEmptyString = function nullToEmptyString(item) {
              return _lodash.default.isNil(item) ? '' : item;
            };

            // passer en revue les filtres
            Object.keys(this.get('filters')).forEach(function (key) {
              var filter = this.get('filters.' + key);
              var realKey = key.replace(/_/g, '.');
              // filtres "contains"
              if (filter.comparator === 'contains' && nullToEmptyString(content.get(realKey)).toLowerCase().indexOf(filter.value.toLowerCase()) === -1) {
                result = false;
              }
              // filtres "equals"
              else if (filter.comparator === 'equals' && filter.value !== '*' && content.get(realKey) !== filter.value) {
                  result = false;
                }
                // filtres "dateEquals"
                else if (filter.comparator === 'dateEquals') {
                    if (_lodash.default.isNil(filter.value)) {
                      // aucune date à filtrer
                    } else {
                      var dateFromFilter = (0, _moment.default)(filter.value);
                      var dateFromObject = (0, _moment.default)(content.get(realKey));

                      if (!dateFromFilter.isSame(dateFromObject, 'day')) {
                        result = false;
                      }
                    }
                  }
                  // filtres "arrayContains"
                  else if (filter.comparator === 'arrayContains' && filter.value !== '*' && !content.get(realKey).includes(filter.value)) {
                      result = false;
                    }
            }, this);

            return result;
          }).property('filters.{' + anyFilteredAttribute + '}.value', 'content.@each.{' + anyFilteredAttribute + '}', 'content.@each'));

          // revenir à la page 1 dès que n'importe quel filtre change
          // (non, filters.@each.value ne fonctionne pas.)
          Object.keys(this.get('filters')).forEach(function (filter) {
            _this2.addObserver('filters.' + filter + '.value', function () {
              this.set('pageNumber', 1);
            });
          });
        }

        // ====== TRI

        if (!_lodash.default.isNil(this.get('columns'))) {
          // Ember ne supporte pas plus d'1 niveau de profondeur lorsque l'on utilise @each
          // donc au lieu de suivre directement "objet.attribut", on suit "objet_attribut"
          // (propriété calculée créée dans setupTransformsAndCustomRenderers)
          var anyDisplayedAttribute = this.get('columns').map(function (col) {
            return col.key.replace(/\./g, '_');
          }).join(',');

          Ember.defineProperty(this, 'sortedContent', Ember.computed('content.@each.{' + anyDisplayedAttribute + '}', 'filteredContent', 'sortField', 'sortOrderAsc', function () {
            var that = this;

            if (!_lodash.default.isNil(this.get('filteredContent'))) {
              return this.get('filteredContent').sort(function (a, b) {
                var aProp = a.get(that.get('sortField'));
                var bProp = b.get(that.get('sortField'));

                // retirer les undefined et les null, qui posent problème au tri
                if (_lodash.default.isNil(aProp)) aProp = '';
                if (_lodash.default.isNil(bProp)) bProp = '';

                if (_lodash.default.isString(aProp) && _lodash.default.isString(bProp)) {
                  // utiliser la comparaison de String locale
                  var compared = aProp.localeCompare(bProp);
                  if (compared !== 0) {
                    return (that.get('sortOrderAsc') ? 1 : -1) * compared;
                  }
                } else if (aProp < bProp) {
                  return that.get('sortOrderAsc') ? -1 : 1;
                } else if (aProp > bProp) {
                  return that.get('sortOrderAsc') ? 1 : -1;
                }

                // en cas d'égalité, on trie par id (si ce champ existe)
                // pour éviter que les lignes changent d'ordre sans arrêt en cas de lazy loading
                // (cf liste des contrôles, tri par motif)
                if (!_lodash.default.isNil(a.get('id')) && !_lodash.default.isNil(b.get('id'))) {
                  if (a.get('id') < b.get('id')) {
                    return that.get('sortOrderAsc') ? -1 : 1;
                  } else if (a.get('id') > b.get('id')) {
                    return that.get('sortOrderAsc') ? 1 : -1;
                  }
                }

                return 0;
              });
            }

            return [];
          }));
        }

        this.set('setUp', true);
      }
    },


    actions: {
      sort: function sort(key, sortable) {
        if (sortable !== false) {
          var asc = !this.get('sortOrderAsc');
          // lorsque l'on clique sur une nouvelle colonne, le tri est par ordre croissant.
          if (key !== this.get('sortField')) {
            asc = true;
          }

          this.set('sortField', key);
          this.set('sortOrderAsc', asc);
        }
      },
      rowClicked: function rowClicked(item) {
        if (this.get('isClickable')) {
          this.sendAction('rowClicked', item);
        }
      },
      goToPage: function goToPage(page) {
        this.set('pageNumber', page);
      },
      actionIconClicked: function actionIconClicked(item, index) {
        this.sendAction('actionIcon' + index + 'Clicked', item);
      },
      sendActionThroughDynamicTable: function sendActionThroughDynamicTable() {
        this.sendAction.apply(this, arguments);
      }
    }
  });
});