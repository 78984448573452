define('sags-front-bo/controllers/administrator/stock-precontrols', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    countPrecontrols: "-",

    getStockPrecontrols: function getStockPrecontrols() {
      var _this = this;

      this.set('countPrecontrols', "");

      return this.get('ajax').request(_environment.default.APP.host + '/control/api/stock-precontrols', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris'
        }
      }).then(function (data) {
        _this.set('countPrecontrols', data);
        _this.get('notifications').success('Demande effectuée avec succès');
      }).catch(function () {
        _this.set('countPrecontrols', "-");
        _this.get('notifications').error('Une erreur est survenue');
      });
    },


    actions: {
      updateCount: function updateCount() {
        this.get('notifications').info('Demande en cours de traitement');
        this.getStockPrecontrols();
      }
    }
  });
});