define('sags-front-bo/routes/controls/nfps', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      controller.set('date', new Date());
      controller.get('initRequest')(controller, this.store);
    }
  });
});