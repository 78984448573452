define('sags-front-bo/router', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('users', function () {
      this.route('create');
      this.route('user', { path: '/:id' }, function () {
        this.route('edit');
      });
      this.route('tour');
    });
    this.route('teams', function () {
      this.route('team', { path: '/:id' }, function () {
        this.route('edit');
      });
      this.route('create');
      this.route('planning');
      this.route('new-planning');
    });
    this.route('rapo', function () {
      this.route('create');
      this.route('detail', { path: '/:number' });
      this.route('edition');
      this.route('create-reset');
      this.route('instruction');
      this.route('rattrap');
    });
    this.route('ccsp-claim', function () {
      this.route('create');
      this.route('create-reset');
      this.route('detail', { path: '/:id' });
    });
    this.route('fps', function () {
      this.route('detail', { path: '/:externalId' });
    });
    this.route('controls', function () {
      this.route('vao');
      this.route('activities');
      this.route('detailed-activities');
      this.route('vehicle-plates');
      this.route('annulation');
      this.route('white-list-edition');
      this.route('monitoring');
      this.route('nfps');
    });
    this.route('administrator', function () {
      this.route('external-server-error');
      this.route('purge-precontrols');
      this.route('locked-precontrols');
      this.route('health');
      this.route('service-accessed');
      this.route('tour-management');
      this.route('stock-precontrols');
    });
    this.route('black-list', function () {
      this.route('create');
      this.route('delete');
      this.route('list');
      this.route('purge');
    });
    this.route('devices', function () {
      this.route('create');
    });
    this.route('reset');

    this.route('gis', function () {
      this.route('file-management');
      this.route('frequency-management');
    });
    this.route('cars', function () {
      this.route('create');
    });

    this.route('sectors', function () {
      this.route('create');
      this.route('assignment');
    });
    this.route('observations', function () {
      this.route('detail', { path: '/:id' });
    });

    this.route('password-reset', function () {
      this.route('init');
      this.route('finish');
    });
    this.route('rules', function () {});
    this.route('white-list-bis', function () {
      this.route('create');
      this.route('delete');
      this.route('purge');
      this.route('nb-plate-characters');
      this.route('control-mode');
      this.route('list');
      this.route('auto-mode');
    });
  });

  exports.default = Router;
});