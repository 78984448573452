define('sags-front-bo/helpers/valid-authorized-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validAuthorizedNumber = validAuthorizedNumber;


  // Vérifie si le numéro d'assermentation est valide pour l'émission
  // de FPS (contient au moins un caractère différent de '0')
  var isAuthorizedNumberValid = function isAuthorizedNumberValid(authorizedNumber, c) {

    for (var i = 0; i < authorizedNumber.length; i++) {
      var currentChar = authorizedNumber[i];

      if (currentChar !== c) {
        return true;
      }
    }

    return false;
  };

  function validAuthorizedNumber(params) {

    var bool = false;
    var authorizedNumber = params[0].user.data.authorizedNumber;

    if (authorizedNumber !== undefined) {
      bool = isAuthorizedNumberValid(authorizedNumber, '0');
    }

    return bool;
  }

  exports.default = Ember.Helper.helper(validAuthorizedNumber);
});