define('sags-front-bo/controllers/controls/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'lodash'], function (exports, _moment, _dictionary, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNT_PER_PAGE = 20;
  var DEFAULT_DEBOUNCE_TIME = 500; // ms
  var FIRST_PAGE = 0;
  var DEFAULT_ORDER = "DESC";
  var DEFAULT_SORTED_FIELD = "datetime";

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    tableColumns: [{
      'key': 'datetime',
      'displayName': 'Date du contrôle',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'parkingStatus',
      'displayName': 'Statut du stationnement',
      transform: function transform(statusId) {
        return _dictionary.default.parkingStatus[statusId];
      }
    }, {
      'key': 'pt.endDate',
      'displayName': 'Date de fin stationnement',
      transform: function transform(date, control) {
        var tickets = control.parkingTickets;
        var lastTicket = null;
        if (!_lodash.default.isEmpty(tickets)) {
          tickets.forEach(function (ticket) {
            if (ticket.endDate === undefined) {
              lastTicket = undefined; // l'une des dates n'est pas chargée => le dernier ticket est indéfini
            } else if (lastTicket !== undefined) {
              if (lastTicket === null || lastTicket.endDate < ticket.endDate) {
                lastTicket = ticket;
                date = lastTicket.endDate;
              }
            }
          });
        }

        if (_lodash.default.isNil(date)) {
          return '...';
        }

        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'vehiclePlate',
      'displayName': 'Immatriculation'
    }, {
      'key': 'streetNumber',
      'displayName': 'N° et voie',
      transform: function transform(address, control) {
        address = '';

        if (!_lodash.default.isNil(control.streetNumber)) {
          address = control.streetNumber;
        }

        if (!_lodash.default.isNil(control.streetNumberBis)) {
          address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetNumberBis[control.streetNumberBis];
        }

        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetType[control.streetType] + ' ' + control.streetName;

        return address;
      }
    }, {
      'key': 'tariffZone',
      'displayName': 'Zone de stationnement',
      'customComponent': 'tariff-zone-element-control'
    }, {
      'key': 'status',
      'displayName': 'Statut',
      'customComponent': 'open-control-pictures'
    }, {
      'key': 'reason',
      'displayName': 'Motif',
      'customComponent': 'control-reason-element'
    }, {
      'key': 'agent.identification',
      'displayName': 'Matricule'
    }, {
      'key': 'fpsLegalId',
      'displayName': 'Référence du FPS',
      'customComponent': 'link-to-detail',
      'sortable': false
    }],

    filters: {
      parkingStatus: {
        comparator: 'equals',
        value: '*'
      },
      vehiclePlate: {
        comparator: 'contains',
        value: ''
      },
      tariffZone: {
        comparator: 'equals',
        value: '*'
      },
      agent_identification: {
        comparator: 'contains',
        value: ''
      },
      address: {
        comparator: 'contains',
        value: ''
      }

    },

    serverSideFilters: {
      startDatetime: {
        comparator: 'dateEquals',
        value: new Date()
      },
      endDatetime: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },

    possibleParkingStatuses: _dictionary.default.convertToArray(_dictionary.default.parkingStatus, [{ key: '*', value: 'Tous' }]),

    linkComponentConfig: {
      targetRoute: 'fps.detail',
      targetItem: 'fpsExternalId'
    },

    needResetChild: false,

    showAll: false,

    // Arrivée sur la page avec l'affichage des contrôles des deux dernières heures
    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller) {
      var startDatetime = new Date();
      startDatetime.setHours(startDatetime.getHours() - 2, startDatetime.getMinutes(), startDatetime.getSeconds());
      controller.set('serverSideFilters.startDatetime.value', startDatetime);

      var endDatetime = new Date();
      controller.set('serverSideFilters.endDatetime.value', endDatetime);

      var bodyRequest = {
        startDatetime: startDatetime.toISOString(),
        endDatetime: endDatetime.toISOString(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE,
        order: DEFAULT_ORDER,
        sortedField: DEFAULT_SORTED_FIELD
      };

      controller.getControls(bodyRequest);
    },
    getControls: function getControls(request, showAll) {
      var _this = this;

      var url = _environment.default.APP.host + '/control/api/controls';

      this.get('ajax').request(url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        data: request,
        contentType: 'application/json'
      }).then(function (controlsPage) {
        showAll ? _this.set('model.controls', _this.get('model.controls').concat(controlsPage.controls)) : _this.set('model', controlsPage);
      });
    },
    getVehiclePlate: function getVehiclePlate() {
      var vehiclePlateSearch = document.getElementById('vehicle-plate-search');
      return vehiclePlateSearch.value.trim() === "" ? null : '%' + vehiclePlateSearch.value.toUpperCase() + '%';
    },
    getParkingStatus: function getParkingStatus() {
      var typeSearch = document.getElementById("type-search");
      return typeSearch.value === '*' ? null : typeSearch.value;
    },
    getTariffZone: function getTariffZone() {
      var tariffZoneSearch = document.getElementById("tariffZone-search");
      return tariffZoneSearch.value === '*' ? null : tariffZoneSearch.value;
    },
    getAgentIdentification: function getAgentIdentification() {
      var agentIdentificationSearch = document.getElementById('agent-identification-search');
      return agentIdentificationSearch.value.trim() === "" ? null : '%' + agentIdentificationSearch.value + '%';
    },
    getAddress: function getAddress() {
      var addressSearch = document.getElementById('address-search');
      return addressSearch.value.trim() === "" ? null : '%' + addressSearch.value.toUpperCase() + '%';
    },
    refreshControls: function refreshControls() {
      var bodyRequest = {
        startDatetime: this.get('serverSideFilters.startDatetime.value').toISOString(),
        endDatetime: this.get('serverSideFilters.endDatetime.value').toISOString(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE,
        order: DEFAULT_ORDER,
        sortedField: DEFAULT_SORTED_FIELD,
        parkingStatus: this.getParkingStatus(),
        tariffZone: this.getTariffZone(),
        vehiclePlate: this.getVehiclePlate(),
        agent: this.getAgentIdentification(),
        address: this.getAddress()
      };

      this.getControls(bodyRequest);

      this.set("needResetChild", !this.get("needResetChild"));
    },


    actions: {
      prepareRefresh: function prepareRefresh(dateUpdated) {
        if (dateUpdated) {
          var endDatetime = new Date(this.get('serverSideFilters.startDatetime.value'));
          endDatetime.setHours(endDatetime.getHours() + 2, endDatetime.getMinutes(), endDatetime.getSeconds());
          $("#control-end-date-search").datetimepicker("date", endDatetime);
          this.set('serverSideFilters.endDatetime.value', endDatetime);
        }
        Ember.run.debounce(this, this.refreshControls, DEFAULT_DEBOUNCE_TIME);
      },
      openPictures: function openPictures(item) {
        var _this2 = this;

        this.set('isControlPhotosVisible', true);

        var url = _environment.default.APP.host + '/control/api/controls/' + item.get('id') + '/pictures';
        this.get('ajax').request(url, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (controlPictures) {
          _this2.set('controlPictures', controlPictures);
          if (!_lodash.default.isEmpty(controlPictures)) {
            _this2.set('mainControlPicture', controlPictures[0]);
          }
        });
      },
      closeControlPicturesModal: function closeControlPicturesModal() {
        this.set('isControlPhotosVisible', false);
        this.set('controlPictures', undefined);
        this.set('mainControlPicture', undefined);
      },
      switchMainImage: function switchMainImage(controlPicture) {
        this.set('mainControlPicture', controlPicture);
      },
      sortControls: function sortControls(params, showAll) {
        var pageNumberToSend = params.pageNumber - 1; // page 1 -> page[0]

        var enabledShowAll = this.get('showAll') !== showAll;

        if (enabledShowAll) this.set('showAll', showAll);

        if (showAll && !pageNumberToSend) this.set('model.controls', []);

        var bodyRequest = {
          startDatetime: this.get('serverSideFilters.startDatetime.value').toISOString(),
          endDatetime: this.get('serverSideFilters.endDatetime.value').toISOString(),
          pageNumber: pageNumberToSend,
          countPerPage: COUNT_PER_PAGE,
          order: params.order,
          sortedField: params.sortedField,
          parkingStatus: this.getParkingStatus(),
          tariffZone: this.getTariffZone(),
          vehiclePlate: this.getVehiclePlate(),
          agent: this.getAgentIdentification(),
          address: this.getAddress()
        };

        this.getControls(bodyRequest, showAll);
      }
    }
  });
});