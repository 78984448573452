define('sags-front-bo/controllers/fps/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'sags-front-bo/helpers/currency-formatter', 'lodash'], function (exports, _moment, _dictionary, _environment, _currencyFormatter, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNT_PER_PAGE = 20;
  var DEFAULT_DEBOUNCE_TIME = 500; // ms
  var FIRST_PAGE = 0;
  var DEFAULT_ORDER = "ASC";
  var DEFAULT_SORTED_FIELD = "legalId";

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    tableColumns: [{
      'key': 'legalId',
      'displayName': 'Numéro',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'type',
      'displayName': 'Statut',
      transform: function transform(type) {
        return _dictionary.default.fpsType[type];
      }
    }, {
      'key': 'c.vehiclePlate',
      'displayName': 'Immatriculation',
      transform: function transform(vehiclePlate, fps) {
        return fps.vehiclePlate;
      }
    }, {
      'key': 'amount',
      'displayName': 'Montant',
      transform: function transform(valueInCents) {
        return _currencyFormatter.default.compute([valueInCents]);
      }
    }, {
      'key': 'creationDate',
      'displayName': 'Date d’émission',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'validityDate',
      'displayName': 'Valide jusqu’au',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'reducedDate',
      'displayName': 'Date de validité du prix réduit',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }],
    filters: {
      legalId: {
        comparator: 'contains',
        value: ''
      },
      vehiclePlate: {
        comparator: 'contains',
        value: ''
      }
    },
    serverSideFilters: {
      creationDate: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },
    possibleFpsTypes: _dictionary.default.convertToArray(_dictionary.default.fpsType, [{ key: '*', value: 'Tous' }]),
    possibleRapoStatuses: _dictionary.default.convertToArray(_dictionary.default.rapoStatus, [{ key: '*', value: 'Tous' }]),
    possibleFpsStatuses: _dictionary.default.convertToArray(_dictionary.default.fpsStatus, [{ key: '*', value: 'Tous' }]),
    linkComponentConfig: {
      targetRoute: 'fps.detail',
      targetItem: 'externalId'
    },

    needResetChild: false,

    showAll: false,

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller) {
      var creationDate = controller.get('serverSideFilters.creationDate.value');
      if (_lodash.default.isNil(creationDate)) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        // Remplir le champ "Date de création" avec la date du jour
        controller.set('serverSideFilters.creationDate.value', today);
      } else {
        creationDate.setHours(0, 0, 0, 0);

        var bodyRequest = {
          creationDate: creationDate.toISOString(),
          pageNumber: FIRST_PAGE,
          countPerPage: COUNT_PER_PAGE,
          order: DEFAULT_ORDER,
          sortedField: DEFAULT_SORTED_FIELD
        };

        controller.getFps(bodyRequest);
      }
    },
    getFps: function getFps(request, showAll) {
      var _this = this;

      var url = _environment.default.APP.host + '/control/api/fps';

      this.get('ajax').request(url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        data: request,
        contentType: 'application/json'
      }).then(function (fpsPage) {
        showAll ? _this.set('model.fps', _this.get('model.fps').concat(fpsPage.fps)) : _this.set('model', fpsPage);
      });
    },
    getVehiclePlate: function getVehiclePlate() {
      var vehiclePlateSearch = document.getElementById('fps-vehicle-plate-search');
      return vehiclePlateSearch.value.trim() === "" ? null : '%' + vehiclePlateSearch.value.toUpperCase() + '%';
    },
    getLegalId: function getLegalId() {
      var legalIdSearch = document.getElementById('fps-legal-id-search');
      return legalIdSearch.value.trim() === "" ? null : '%' + legalIdSearch.value.toUpperCase() + '%';
    },
    refreshFps: function refreshFps() {
      var bodyRequest = {
        creationDate: this.get('serverSideFilters.creationDate.value').toISOString(),
        pageNumber: FIRST_PAGE,
        countPerPage: COUNT_PER_PAGE,
        order: DEFAULT_ORDER,
        sortedField: DEFAULT_SORTED_FIELD,
        vehiclePlate: this.getVehiclePlate(),
        legalId: this.getLegalId()
      };

      this.getFps(bodyRequest);

      this.set("needResetChild", !this.get("needResetChild"));
    },


    actions: {
      prepareRefresh: function prepareRefresh() {
        Ember.run.debounce(this, this.refreshFps, DEFAULT_DEBOUNCE_TIME);
      },
      sortFps: function sortFps(params, showAll) {
        var pageNumberToSend = params.pageNumber - 1; // page 1 -> page[0]

        var enabledShowAll = this.get('showAll') !== showAll;

        if (enabledShowAll) this.set('showAll', showAll);

        if (showAll && !pageNumberToSend) this.set('model.fps', []);

        var bodyRequest = {
          creationDate: this.get('serverSideFilters.creationDate.value').toISOString(),
          pageNumber: pageNumberToSend,
          countPerPage: COUNT_PER_PAGE,
          order: params.order,
          sortedField: params.sortedField,
          vehiclePlate: this.getVehiclePlate(),
          legalId: this.getLegalId()
        };

        this.getFps(bodyRequest, showAll);
      }
    }
  });
});