define('sags-front-bo/routes/fps/detail', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary'], function (exports, _environment, _accessController, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model(params) {
      var query = this.store.queryRecord('fps', { externalId: params.externalId });
      return query;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var externalId = model.get('externalId');
      var url = _environment.default.APP.host + '/control/api/fps/' + externalId + '/pictures';
      this.get('ajax').request(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (data) {
        var preview = 'data:image/png;base64,';
        controller.set('picturePlate', data[0] ? preview + data[0].base64 : null);
        controller.set('picturePlateZoom', data[1] ? preview + data[1].base64 : null);
        controller.set('pictureOverview', data[2] ? preview + data[2].base64 : null);
        // Indicateur de visibilité des photos de contexte FPS
        controller.set('isFpsPicturesVisible', false);
      });

      // On récupère les settings du serveur
      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return controller.set('tariffZones', _dictionary.default.convertToArray(settings.tariffZonesFps));
      });
    },
    resetController: function resetController(controller) {
      controller.set('picturePlate', null);
      controller.set('picturePlateZoom', null);
      controller.set('pictureOverview', null);
    },


    actions: {
      backToDetailFps: function backToDetailFps() {
        this.get('controller').get('serverSideFilters.creationDate.value', this.get('controller.model.creationDate'));
        this.transitionTo('fps');
      },
      goToCcspClaimCreation: function goToCcspClaimCreation() {
        this.transitionTo('ccsp-claim.create', { queryParams: { legalId: this.get('controller.model.legalId') } });
      }
    }
  });
});